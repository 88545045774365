<template>
    <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
            <vx-card>
                <span>
                    <h4 class="mb-5">List Price</h4>
                </span>
                <div class="vx-row mb-6" style="width:100%;">
                    <vs-button
                        class="ml-4 mt-2"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-arrow-left"
                        @click="handleBack()"
                    >Back</vs-button>
                </div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Code</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="Code" v-model="data.code" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Name</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="Name" v-model="data.name" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Notes</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-textarea class="w-full" name="Notes" v-model="data.notes" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Model</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="Model" v-model="data.model" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid From</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="Valid From" v-model="data.valid_from" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid To</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="Valid To" v-model="data.valid_to" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Items</span>
					</div>
				</div>
                <vs-table 
                    stripe
                    border
                    description 
                    :sst="true"
                    :data="items"
                    class="vx-row mb-6 ml-6 mr-6"
                    
                >
                    <template slot="thead">
                        <vs-th>No</vs-th>
                        <vs-th>SKU Code</vs-th>
                        <vs-th>Item Name</vs-th>
                        <vs-th>Handling Unit</vs-th>
                        <vs-th>Qty From</vs-th>
                        <vs-th>Qty To</vs-th>
                        <vs-th>Price</vs-th>
                        <vs-th>Discountable</vs-th>
                    </template>
                    <template :data="items">
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in items">
                            <vs-td>
                                <span v-if="items[indextr].num != 0" >{{items[indextr].num}}.</span>
                                <span v-else></span>
                            </vs-td>
                            <vs-td>
                                <span>{{items[indextr].sku_code}}</span>
                                <!-- <span v-if="items[indextr].num != 0" >{{items[indextr].sku_code}}</span> -->
                                <!-- <span v-else></span> -->
                            </vs-td>
                            <vs-td>
                                <span>{{items[indextr].item_name}}</span>
                                <!-- <span v-if="items[indextr].num != 0" >{{items[indextr].item_name}}</span> -->
                                <!-- <span v-else></span> -->
                            </vs-td>
                            <vs-td>
                                <span>{{items[indextr].item_unit}}</span>
                                <!-- <span v-if="items[indextr].num != 0" >{{items[indextr].item_unit}}</span> -->
                                <!-- <span v-else></span> -->
                            </vs-td>
                            <vs-td>{{items[indextr].qty_from}}</vs-td>
                            <vs-td>{{items[indextr].qty_to}}</vs-td>
                            <vs-td>{{data.currency_code}} {{items[indextr].price}}</vs-td>
                            <vs-td>{{items[indextr].discount}}</vs-td>
                        </vs-tr>
                    </template>
                </vs-table>              
            </vx-card>
        </div>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    created() {
        this.getData()
    },
    data() {
        return this.initialState();
    },
    methods: {
        initialState() {
            return {
                items: [],
                data: {
                    code: "",
                    name: "",
                    notes: "",
                    model: false,
                    valid_from: "",
                    valid_to: "",
                    currency_code: ""
                }
            };
        },
        handleBack() {
            this.$router.push("/master/pricing-management-v2");
        },
        getData() {
        this.$vs.loading();
            if (this.$route.params.id) {
                this.$http
                .get("/api/v1/master/price/" + this.$route.params.id)
                .then(resp => {
                    this.$vs.loading.close();
                    var _this = this
                    if (resp.code == 200) {
                        this.data.code = resp.data.code;
                        this.data.name = resp.data.name;
                        this.data.notes = resp.data.notes;
                        this.data.model = resp.data.model;
                        this.data.currency_code = resp.data.currency_code;
                        this.data.valid_from = resp.data.valid_from.substr(0, 10);
                        this.data.valid_to = resp.data.valid_to.substr(0, 10);
                        this.items = resp.data.list;
                        resp.data.list.forEach(function(element, index){
                            _this.items[index]["price"] = element.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            _this.items[index]["qty_from"] = element.qty_from.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            _this.items[index]["qty_to"] = element.qty_to.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        })
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                })
                .catch(error => {
                    this.$vs.loading.close();
                    console.log(error);
                });
            }
        },
        
    },
};
</script>